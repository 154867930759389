import appAndPages from "./app-and-pages"
import accessManagement from "./access-management"
import companyManagement from "./company-management"
import employeeManagement from "./employee-management"
import notificationManagement from "./notification-management"
import advanceRequestManagement from "./advance-request-management"
import chartsAndMaps from "./charts-and-maps"
import dashboard from "./dashboard"
import formsAndTables from "./forms-and-tables"
import others from "./others"
import uiElements from "./ui-elements"

// Array of sections
export default [
	...dashboard,
	...accessManagement,
	...companyManagement,
	...employeeManagement,
	...advanceRequestManagement,
	...notificationManagement,
	...appAndPages,
	// ...uiElements,
	// ...formsAndTables,
	// ...chartsAndMaps,
	// ...others
]
